import axios from 'axios';
import prependApiUrl from '../utils/prepend-api-url';

import { IGrajWKlasykeValues } from '../formik/graj-w-klasyke-form.config';
import { IGrajWKlasykeWinnerValues } from '../formik/graj-w-klasyke-form-winner.config';

export const grajWKlasykeFormMutation = (data: IGrajWKlasykeValues) => {
    return axios.post(prependApiUrl('/graj-w-klasyke/create'), {
        ...data,
    });
};

export const postGrajWKlasykeWinner = (createForm: IGrajWKlasykeWinnerValues) => {
    return axios.post(prependApiUrl('/graj-w-klasyke/addresses'), {
        ...createForm,
    });
};

export const getGrajWKlasykeCheckToken = (token: string) => {
    return axios.get(prependApiUrl(`/graj-w-klasyke/check/${token}`), {});
};

export const getGrajWKlasykeScoreboard: () => Promise<{ data: { nick: string; score: number }[] }> =
    () => {
        return axios.get(prependApiUrl(`/graj-w-klasyke/scoreboard`), {});
    };
