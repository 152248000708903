import React, { useState } from 'react';
import { Form, Formik, ErrorMessage, Field, FormikConfig } from 'formik';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { useMutation } from 'react-query';

import {
    container,
    header,
    title,
    info,
    icon,
    input,
    error,
    loading,
    button,
    fields,
    full,
    success,
} from './graj-w-klasyke-winner-form.module.scss';

import WinnerIcon from '../../assets/images/svg/winner.svg';

import pagesContext from '../../config/pages-context';
import {
    getInitialValues,
    getValidationSchema,
    IGrajWKlasykeWinnerValues,
} from '../../formik/graj-w-klasyke-form-winner.config';
import { getGrajWKlasykeCheckToken, postGrajWKlasykeWinner } from '../../api-ssr/graj-w-klasyke';
import useContestTokenCheck from '../../hooks/use-contest-token-check';

import Button from '../atoms/button';
import Captcha from '../atoms/captcha';
import Loader from '../atoms/loader';
import GrajWKlasykePrize from './graj-w-klasyke-prize';

const RECAPTCHA = process.env.RECAPTCHA;

interface IGrajWKlasykeWinnerFormProps {
    className?: string;
}

const GrajWKlasykeWinnerForm: React.FC<IGrajWKlasykeWinnerFormProps> = ({ className = '' }) => {
    const { t, language } = useI18next();
    const [isTokenCheckLoading, setIsTokenCheckLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const { mutateAsync, isSuccess } = useMutation(postGrajWKlasykeWinner);

    const onLoad = () => {
        setIsTokenCheckLoading(false);
    };

    const token = useContestTokenCheck(language, getGrajWKlasykeCheckToken, redirectPages, onLoad);

    const handleSubmit: FormikConfig<IGrajWKlasykeWinnerValues>['onSubmit'] = async (values) => {
        setIsLoading(true);
        try {
            await mutateAsync(values);
        } catch (error: any) {
            const errorMessage = error?.messages?.map((message: any) => message.content).join(' ');
            alert(errorMessage || error);
        } finally {
            setIsLoading(false);
        }
    };

    if (isTokenCheckLoading) {
        return <Loader />;
    }

    if (isSuccess) {
        return (
            <div className={`${container} ${success}`}>
                <div className={header}>
                    <WinnerIcon className={icon} />
                    <h1 className={title}>{t('graj.w.klasyke.winner.form.success.title')}</h1>
                    <p className={info}>{t('graj.w.klasyke.winner.form.success.info')}</p>
                </div>
                <GrajWKlasykePrize />
            </div>
        );
    }

    return (
        <Formik
            initialValues={getInitialValues(token)}
            onSubmit={handleSubmit}
            validationSchema={getValidationSchema(t)}
            enableReinitialize={true}
        >
            {() => (
                <Form className={`${container} ${className} ${isLoading ? loading : ''}`}>
                    <div className={header}>
                        <WinnerIcon className={icon} />
                        <h1 className={title}>{t('graj.w.klasyke.winner.form.title')}</h1>
                        <p className={info}>{t('graj.w.klasyke.winner.form.info')}</p>
                    </div>
                    <div className={fields}>
                        <div>
                            <Field
                                className={input}
                                name="firstname"
                                placeholder={t('graj.w.klasyke.winner.form.firstname.placeholder')}
                            />
                            <ErrorMessage name="firstname" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                className={input}
                                name="lastname"
                                placeholder={t('graj.w.klasyke.winner.form.lastname.placeholder')}
                            />
                            <ErrorMessage name="lastname" className={error} component="p" />
                        </div>
                        <div className={full}>
                            <Field
                                type="email"
                                className={input}
                                name="email"
                                placeholder={t('graj.w.klasyke.winner.form.email.placeholder')}
                            />
                            <ErrorMessage name="email" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                className={input}
                                type="tel"
                                name="phone"
                                placeholder={t('graj.w.klasyke.winner.form.phone.placeholder')}
                            />
                            <ErrorMessage name="phone" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                className={input}
                                name="street"
                                placeholder={t('graj.w.klasyke.winner.form.street.placeholder')}
                            />
                            <ErrorMessage name="street" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                className={input}
                                name="houseNumber"
                                placeholder={t(
                                    'graj.w.klasyke.winner.form.houseNumber.placeholder'
                                )}
                            />
                            <ErrorMessage name="houseNumber" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                className={input}
                                name="apartmentNumber"
                                placeholder={t(
                                    'graj.w.klasyke.winner.form.apartmentNumber.placeholder'
                                )}
                            />
                            <ErrorMessage name="apartmentNumber" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                className={input}
                                name="zipCode"
                                placeholder={t('graj.w.klasyke.winner.form.zipCode.placeholder')}
                            />
                            <ErrorMessage name="zipCode" className={error} component="p" />
                        </div>
                        <div>
                            <Field
                                className={input}
                                name="city"
                                placeholder={t('graj.w.klasyke.winner.form.city.placeholder')}
                            />
                            <ErrorMessage name="city" className={error} component="p" />
                        </div>
                    </div>
                    <Button type="submit" className={button}>
                        {t('graj.w.klasyke.winner.form.submit')}
                    </Button>
                    {RECAPTCHA && <Captcha siteKey={RECAPTCHA} />}
                </Form>
            )}
        </Formik>
    );
};

const redirectPages = {
    default: pagesContext.grajWKlasyke.slug,
    expired: pagesContext.grajWKlasykeExpired.slug,
};

export default GrajWKlasykeWinnerForm;
