import React from 'react';
import { graphql } from 'gatsby';
import { QueryClient, QueryClientProvider } from 'react-query';

import { container, form, layout } from './winner.module.scss';
import { IPage } from '../../../models/page.model';

import GrajWKlasykeLayout from '../../../layouts/graj-w-klasyke-layout';
import GrajWKlasykeWinnerForm from '../../../components/organisms/graj-w-klasyke-winner-form';

interface IGrajWKlasykeWinnerPageProps {
    readonly data: {
        page: IPage | null;
    };
}

const Winner: React.FC<IGrajWKlasykeWinnerPageProps> = ({ data }) => {
    const { page } = data;
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <GrajWKlasykeLayout page={page} className={container} layoutClass={layout}>
                <GrajWKlasykeWinnerForm className={form} />
            </GrajWKlasykeLayout>
        </QueryClientProvider>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default Winner;
