// extracted by mini-css-extract-plugin
export var backgroundAnimation = "graj-w-klasyke-winner-form-module--backgroundAnimation--33a0b";
export var button = "graj-w-klasyke-winner-form-module--button--764a7";
export var container = "graj-w-klasyke-winner-form-module--container--875e8";
export var error = "graj-w-klasyke-winner-form-module--error--ff3d2";
export var errorBlink = "graj-w-klasyke-winner-form-module--error-blink--bf0a4";
export var fields = "graj-w-klasyke-winner-form-module--fields--26af7";
export var full = "graj-w-klasyke-winner-form-module--full--d0b8a";
export var header = "graj-w-klasyke-winner-form-module--header--2920d";
export var icon = "graj-w-klasyke-winner-form-module--icon--f6db4";
export var info = "graj-w-klasyke-winner-form-module--info--7b13b";
export var input = "graj-w-klasyke-winner-form-module--input--a2540";
export var loading = "graj-w-klasyke-winner-form-module--loading--2763f";
export var success = "graj-w-klasyke-winner-form-module--success--750c3";
export var title = "graj-w-klasyke-winner-form-module--title--60b1e";